.MyImageDiv {
  height: calc(100vh - 3rem);
}

@media (max-width: 600px) {
  .MyImageDiv {
    margin-top: 100px;
    height: auto;
  }
}
