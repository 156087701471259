.Wrapper {
  text-align: center;
  background-color: rgb(254, 253, 247);
  width: 100vw;
  height: 100vh;
  border-radius: 20px;
  border: 4px solid rgb(82, 68, 59);
  box-sizing: border-box;

  color: rgb(82, 68, 59);
}

.WavingHand {
  transform-origin: bottom left;
  animation: waveAnimation 1s forwards;
}

@keyframes waveAnimation {
  0%,
  50%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-60deg);
  }
  75% {
    transform: rotate(-60deg);
  }
}
