/* Hide scrollbar for Chrome, Safari and Opera */
html {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* For Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  display: none;
}
